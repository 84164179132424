export const STORAGES = {
  token: "token",
  id: "id",
  theme: "theme",
  formLinkClicked: "formLinkClicked",
  isInfoStripClosed: "isInfoStripClosed",
  referralCode: "referralCode",
};

export const SUPPORTED_THEMES = {
  light: "light",
  dark: "dark",
};

export const PalleteColorTypes = [
  "gradient",
  "primary",
  "secondary",
  "tertiary",
  "white",
  "black",
];

export const SUPPORTED_BLOCKCHAINS = {
  ethereum: "ethereum",
};

export const SUPPORTED_ETHEREUM_NETWORKS = {
  sepolia: "sepolia",
  mainnet: "testnet",
};

export const SUPPORTED_NETWORKS = {
  [SUPPORTED_BLOCKCHAINS.ethereum]: {
    mainnet: {
      name: SUPPORTED_ETHEREUM_NETWORKS.mainnet,
      chain_id: 1,
    },
    sepolia: {
      name: SUPPORTED_ETHEREUM_NETWORKS.sepolia,
      chain_id: 11155111,
    },
  },
};


export const COMPONENT_SIZES = {
  X_SMALL: "x-small",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const SUPPORTED_SOCIAL_PLATFORMS = {
  twitter: "twitter",
  instagram: "instagram",
  tiktok: "tiktok",
  youtube: "youtube",
};

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
];

export const APP_SOCIAL_LINKS = {
  INSTAGRAM: "https://www.instagram.com/soshnft/",
  TWITTER: "https://twitter.com/SoshNFT",
  YOUTUBE: "https://www.youtube.com/channel/UCON-yDWn8cSJDyMkkGmT3mA",
  TIKTOK: "https://www.tiktok.com/@soshnft",
};

export const SUPPORT_MAIL_ADDRESS = "support@soshnft.io";

export const SUPPORTED_VIDEO_FORMATS = ["video/mp4", "video/webm"];
